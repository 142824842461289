<template>
    <div class="container">
        <header class="jumbotron">
            <h3>Dodaj użytkownika</h3>
        </header>
        <div class="p-fluid p-formgrid p-grid">

            <div class="p-field p-col-12">
                <label for="username">Nazwa użytkownika</label>
                <InputText id="username" type="text" v-model="content.username" placeholder="Wpisz nazwę użytkownika"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="firstName">Imię</label>
                <InputText id="firstName" type="text" v-model="content.firstName" placeholder="Wpisz imię"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="lastName">Nazwisko</label>
                <InputText id="lastName" type="text" v-model="content.lastName" placeholder="Wpisz nazwisko"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="email">E-mail</label>
                <InputText id="email" type="text" v-model="content.email" placeholder="Wpisz e-mail"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="units">Jednostka</label>
                <MultiSelect id="units" v-model="content.units" optionValue="id" :showToggleAll="false" :options="units" optionLabel="name"
                          placeholder="Wybierz jednostkę"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="role">Rola</label>
                <Dropdown id="role" v-model="content.roles[0]" optionValue="id" :options="roles" optionLabel="name"
                          placeholder="Wybierz rolę"/>
            </div>
        </div>
        <div class="p-field p-col-12 p-md-6 fa-pull-right">
            <Button type="button" class="p-button-secondary" label="Wróć" v-on:click="goBack"/>
            <Button type="button" label="Zapisz" v-on:click="click"/>
        </div>
    </div>
</template>

<script>
    import UserService from "../../services/user.service";
    import RolesService from "../../services/roles.service";
    import UnitService from "../../services/unit.service";

    export default {
        inject: ['global'],
        name: "AddEditUser",
        props: {
            initialCntent: {
                // eslint-disable-next-line vue/require-valid-default-prop
                type: Object, default: {
                    username: "",
                    email: "",
                    firstName: "",
                    lastName: "",
                    units: [],
                    roles: [
                        0
                    ]
                }
            },
        },
        data() {
            return {
                roles: '',
                units: '',
                content: this.initialCntent,
            };
        },
        methods: {
            click: function () {
                if (this.mode === 'edit') {
                    UserService.editUser(this.content, this.$route.params.userId).then(
                        (response) => {
                            console.log(response);
                            this.$router.push({path: `${this.global.state.instancePatch}/uzytkownicy/lista`});
                        },
                        (error) => {
                            this.$toast.add({
                                severity: 'error',
                                summary: error.response.data.error,
                                detail: error.response.data.message,
                                life: 3000
                            });
                        }
                    );
                } else {
                    UserService.addUser(this.content).then(
                        (response) => {
                            console.log(response);
                            this.$router.push({path: `${this.global.state.instancePatch}/uzytkownicy/lista`});
                        },
                        (error) => {
                            this.$toast.add({
                                severity: 'error',
                                summary: error.response.data.error,
                                detail: error.response.data.message,
                                life: 3000
                            });
                        }
                    );
                }
            },
            goBack: function () {
                this.$router.push({path: `${this.global.state.instancePatch}/uzytkownicy/lista`});
            }
        },
        mounted() {
            this.mode = this.$route.params.userId ? 'edit' : 'add';
            if (this.mode === 'edit') {
                UserService.getUser(this.$route.params.userId).then(
                    (response) => {
                        this.content = response.data;
                        this.content.units = this.content.units.map(unit => unit.id);
                        this.content.roles = this.content.roles.map(role => role.id);
                        this.content.id = undefined;
                        this.content.createdAt = undefined;
                        this.content.updatedAt = undefined;
                        this.content.isAdmin = undefined;
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            } else {
                this.content = {
                    username: "",
                    email: "",
                    firstName: "",
                    lastName: "",
                    units: [],
                    roles: [
                        0
                    ]
                };
            }
            RolesService.getAllRoles().then(
                (response) => {
                    this.roles = response.data.items;
                },
                (error) => {
                    this.roles =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
            UnitService.getAllUnitsList().then(
                (response) => {
                    this.units = response.data;
                },
                (error) => {
                    this.units =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    };
</script>
