import api from './api';

const API_URL = `/roles`;

class UnitService {
    getAllRoles() {
        return api.get(API_URL);
    }

    addRole(content) {
        return api.post(API_URL, content);
    }

    editRole(content, id) {
        return api.patch(`${API_URL}/${id}`, content);
    }

    getRole(id) {
        return api.get(`${API_URL}/${id}`
        );
    }

    removeRole(id) {
        return api.delete(`${API_URL}/${id}`
        );
    }

}

export default new UnitService();
