import api from './api';

const API_URL = `/units`;

class UnitService {
    getAllUnits() {
        return api.get(API_URL);
    }

    getAllUnitsList() {
        return api.get(`${API_URL}/list`);
    }

    getUnitsTypes() {
        return api.get(`/unit-types`);
    }

    addUnit(content) {
        return api.post(API_URL, content);
    }

    editUnit(content, id) {
        return api.put(`${API_URL}/${id}`, content);
    }

    getUnit(id) {
        return api.get(`${API_URL}/${id}`
        );
    }

    removeUnit(id) {
        return api.delete(`${API_URL}/${id}`
        );
    }

}

export default new UnitService();
