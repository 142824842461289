import api from './api';

const API_URL = `/users`;

class UserService {
  getPublicContent() {
    return api.get(API_URL + '/me');
  }

  getAllUsers() {
    return api.get(API_URL);
  }

  addUser(content) {
    return api.post(API_URL, content);
  }

  editUser(content, id) {
    return api.patch(`${API_URL}/${id}`, content);
  }

  getUser(id) {
    return api.get(`${API_URL}/${id}`
    );
  }

  removeUser(id) {
    return api.delete(`${API_URL}/${id}`
    );
  }

  chongePassword(content) {
    return api.patch(`${API_URL}/password`, content);
  }

  getUserBoard() {
    return api.get(API_URL + 'user');
  }

  getModeratorBoard() {
    return api.get(API_URL + 'mod');
  }

  getAdminBoard() {
    return api.get(API_URL + 'admin');
  }
}

export default new UserService();
